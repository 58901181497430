import * as React from "react";

// Styles
import "./index.scss";

const Copied = () => {
  return <div className="copied">copied 👍🏻</div>;
};

export default Copied;
